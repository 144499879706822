/* component */

.star-rating {
    margin: 20px;
    border:solid 1px #ccc;
    display:flex;
    flex-direction: row-reverse;
    font-size:1.5em;
    justify-content:space-around;
    padding:0 .2em;
    text-align:center;
    width:5em;
  }
  
  .star-rating input {
    display:none;
  }
  
  .star-rating label {
    color:#ccc;
    cursor:pointer;
  }
  
  .star-rating :checked ~ label {
    color:#f90;
  }
  
  .star-rating label:hover,
  .star-rating label:hover ~ label {
    color:#fc0;
  }
  
  
  
  
  .rating {
    color: #d47500;
    padding: 0 1px;
  }