*{
  font-family: lato, sans-serif;
}
body {
  text-align: center;
  background: url(http://www.graphicartsunit.com/images/noise2.png);
  line-height: 1.5;
}

.title{
  margin: 0;
 
  color: #9B224A;
  font-weight: bold;
  font-size: 10vw;
  text-shadow: 2px 2px paleturquoise;
}

@media screen and (min-width: 730px){
  .title{
    font-size: 7vw;
  }
}

@media screen and (min-width: 1025px){
  .title{
    font-size: 5vw;
  }
}


